/* src/styles.css */

@font-face {
    font-family: 'Roboto-Regular';
    src: url('../public/Roboto-Regular.ttf') format('truetype');
         
    font-weight: normal;
    font-style: normal;
  }  
  body {
    font-family: 'Roboto-Regular', sans-serif;
  }


  